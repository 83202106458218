import styled from "styled-components"

import { useTranslate } from "src/i18n/useTranslate"
import { TextButton } from "src/ui/Button/TextButton"
import { CheckCircleIcon } from "src/ui/CheckCircleIcon/CheckCircleIcon"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function Step({
  title,
  stepIndex,
  showCheckmark,
  preview,
  hidePreview,
  onEdit,
  editable,
  children,
  key,
}: {
  title: React.ReactNode
  stepIndex: number
  showCheckmark?: boolean
  preview?: React.ReactNode
  hidePreview?: boolean
  onEdit?: (stepNumber: number) => void
  editable?: boolean
  children?: React.ReactNode
  key?: React.Key
}) {
  const { langKeys, t } = useTranslate()

  return (
    <div key={key}>
      <Top>
        <CheckmarkAndTitle>
          {showCheckmark ? (
            <StyledCheckmark svgProps={{ width: 20 }} />
          ) : (
            <MText variant="heading2" as="span">
              {stepIndex + 1}.
            </MText>
          )}
          <div>
            <MText variant="heading2" as="span">
              {title}
            </MText>
            {!hidePreview && preview && (
              <MText variant="bodyS" color="secondary">
                {preview}
              </MText>
            )}
          </div>
        </CheckmarkAndTitle>
        {editable && (
          <TextButton onClick={() => onEdit && onEdit(stepIndex)}>
            {t(langKeys.edit)}
          </TextButton>
        )}
      </Top>
      {children}
    </div>
  )
}

const Top = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

const CheckmarkAndTitle = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: flex-start;
  gap: ${spacing.S};
`

const StyledCheckmark = styled(CheckCircleIcon)`
  margin-top: ${spacing.XS};
`
